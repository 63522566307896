import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import Layout from "../../components/layout"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import {
  SectionHeading,
} from "../../styledComponents/section"
import HeroBanner from "../../components/HeroBanner"
import { HeroText, HeroDescription } from "../../styledComponents/heroBanner"
import PatTestimonials from "../../components/Program/PermissioningAndTransformation/testimonials"
import Testimonials from "../../components/testimonials"
import { StyledAnchorLink, StyledButtonAsLink, StyledCloseModalButton } from "../../styledComponents/button"
import Icon from "../../components/icon"
import { StyledModal } from "../../styledComponents/modal"
import ProductList from "../../components/ProductList"
import SEO from "../../components/seo"
import { ProductLanderSection } from "../../styledComponents/productLander"
import { analyticClickEvent } from "../../components/googleAnalytics"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import { StyledBlockQuote } from "../../styledComponents/base"
import { ReactVideoPlayer } from "../../components/reactPlayer"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
    analyticClickEvent("Kaye bio info button","CTA Button", "Permissioning & Transformation")
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      <p>Karina's teacher Kaye W.Lee is the creator and founder of Permissioning and Transformation™. <StyledButtonAsLink onClick={toggleModal} className="learn-more" >You can read Kaye's bio here.</StyledButtonAsLink> </p>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="order-modal"
      >
        <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>

        <h2>Kaye W. Lee</h2>

        <h3>Founder of Permissioning and Transformation™</h3>
        <p>Kaye began his journey into the world of meditation and self-awareness in Malaysia at the age of twelve when he began studying how to accelerate his mind. Whilst using these skills to dramatically advance his academic achievements he joyfully discovered that he was also able to program his mind to develop a specific type of singing voice.</p>
        <p>Having excelled throughout his youth in various styles of martial arts, his ability to harness his own energetic abilities took him to a high level at a very young age.</p>
        <p>This first step began the journey that ultimately took him around the world to train in both western and eastern skills in diverse subjects on healing, religion, spirituality, Feng Shui, self-development and accelerated learning.</p>
        <p>He has had the privilege of having been personally taught by many leading teachers, including Jeddah Mali, Chunyi Lin, Paul Scheele, Win Wenger and others.</p>
        <p>Amongst Master Lee’s many “titles” he is also a certified trainer in the Beyond Einstein releasing-genius training, and is also the only known PhotoReader who has completed his MA with accelerated learning from the University of Cambridge.</p>
        <p>In 2020 Kaye was also awarded a first class MBA qualification by Durham University.</p>
        <p>Master Lee is regularly consulted “behind the scenes” by personal development leaders and has been described as “indescribable”.</p>
      </StyledModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
  z-index: 100;
`

const Permissioning = ({ data, location, showMoreTestimonials }) => {
  let products = data.allWpProduct.edges
  const permissioningTestimonials = data.permissioningTestimonials.content
  const [isTestimonialsOpen, setIsTestimonialsOpen] = useState(false);

  const toggleTestimonials = (value) => {
    analyticClickEvent("engagement", "CTA Button","PAT More testimonials",'')
    setIsTestimonialsOpen(!isTestimonialsOpen)
  }
  return (
    <>
      <Layout location={location} isHomePage>
        <SEO 
          title="Permissioning &amp; Transformation™" 
          description="Permissioning &amp; Transformation™ is a set of techniques that are very effective in getting rid of negative emotions and solving problematic situations" 
          url={data.wpPage.uri}
          image="/images/permissioning-buttefly.jpg"
        />
        <HeroBanner
          image="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/wp-content/uploads/2019/09/bloom-blooming-blossom-462118-1320x880.jpg"
          backgroundPosition="0 0"
          largeBackgroundPosition="0 -260px"
          largeBackgroundSize="100%"
          type="pat"
        >
          <HeroText>
            <HeroDescription>Permissioning &amp; Transformation™ is a set of techniques that are very effective in getting rid of negative emotions and solving problematic situations.</HeroDescription>
            <StyledAnchorLink inverse="true" className="learn-more" to="/permissioning-and-transformation/#info">Find out more</StyledAnchorLink>
          </HeroText>
        </HeroBanner>
        <ProductLanderSection id="info">
             <article>
                <p>Permissioning and Transformation is a very popular self-help technique that is working wonders for people from all walks of life.</p>
                <p>This includes healthcare professionals, business leaders, parents, teens and anyone wanting to bring much more inner peace and balance into their lives.</p>
                <p>It is an elegant process that is incredibly simple to learn and is a pathway into maintaining a peaceful state of being.</p>
                <p>This technique is designed to literally free your mind from any fixed viewpoints, as you experience life with new and open perspectives. As you apply the technique you will enter into a state of presence very quickly. This creates a powerful healing space where peace, fresh insights,  and an open mind become your default setting.</p>
                <p>Permissioning and Transformation can be applied to all areas of life such as health, relationships, studies, wealth and success.</p>
                <ModalProvider backgroundComponent={FadingBackground}>
                  <FancyModalButton />
                </ModalProvider>
                <aside className="nursing-midwifery-points">
                  <p>Permissioning and Transformation programs are approved to count towards the 35 hours of Nursing and Midwifery council revalidation points.</p>
                  <p>Please contact us if you would like a Permissioning and Transformation session in your workplace.</p>
                </aside>
              
             </article>
        </ProductLanderSection>
       
        <BackgroundColourBlock number='2'>
          <ProductLanderSection id="info" background='var(--grey-lightest)'>
            <Heading level={1}>Permissioning programs</Heading>
            <ProductList posts={products} type="permissioning"/>
          </ProductLanderSection>
        </BackgroundColourBlock>
        <Testimonials 
          data={data} 
          showTestimonialsSectionHeader={true} 
          showMoreTestimonialsButton={true} 
          showMoreTestimonialsInPage={true} 
          showMoreTestimonialsButtonText="View more"
          isTestimonialsOpen={isTestimonialsOpen}
          setIsTestimonialsOpen={setIsTestimonialsOpen}
          toggleTestimonials={toggleTestimonials}
        />
        {isTestimonialsOpen && 
          <ProductLanderSection>
            <PatTestimonials testimonials={permissioningTestimonials} />
          </ProductLanderSection>
        }

        
        <StyledBlockQuote>
            <Icon name="singlequote" /><p>I've used Permissioning and Transformation™ to treat patients who are suffering from anxiety. I've seen it help alleviate overthinking and mental stress very, very rapidly. <strong>I don't know any other technique that's easier or more effective.</strong></p>
            <ReactVideoPlayer
            videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/testimonials/sleep/lisa-sleep-testimonial.mp4" 
            title="Sleep Testimonials combination"
            placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/testimonials/sleep/lisa-sleep-placeholder.jpg"
            />
            <cite>Lisa Jennings, Former NHS Psychotherapist (now in private practice) &amp; Nutritionist, London UK</cite>
        </StyledBlockQuote>

        <StyledBlockQuote>
            <Icon name="singlequote" /><p>I've been using the program for 2 months now and my sleep continues to improve. <strong>Even thinking about the techniques helps trigger me falling asleep.</strong> I would definitely recommend this, it's easy and is literally just listening to a process on a couple of audios. What could be more simple than that?</p>
            <ReactVideoPlayer
                videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/testimonials/sleep/kully-sleep.mp4" 
                title="Sleep Testimonials combination"
                placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/testimonials/sleep/kully-sleep-placeholder.jpg"
            />
            <cite><em>Kully M., Software Developer, London UK</em></cite>
        </StyledBlockQuote>

        <StyledBlockQuote>
            <Icon name="singlequote" /> <p>I'm Samanthi from Sri Lanka. I have a building materials business and cosmetics manufacturing business. Im a student of Karina. I participated in the permissioning to reset your nervous system workshop in January. I practice the daily routine of permissioning. This makes me so happy and really reduces my stress. I'm addicted to doing permissioning daily. I do it every day without missing a single day. I can't carry out anything continuously but with this permissioning, I do it everyday. I feel so relaxed and happy with permissioning. I use this for stillness. Thank you so much Karina for teaching us these amazing techniques. This is like magic. I'm so grateful for you Karina teaching us these amazing techniques.</p>
            <ReactVideoPlayer
                videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/testimonials/samanthi-testimonial.mp4" 
                title="Testimonial from Samanthi from Sri Lanka"
                placeholder="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/testimonials/samanthi-placeholder-video.jpg"
            />
            <cite><em>Samanthi, Sri Lanka</em></cite>
        </StyledBlockQuote>
         <BackgroundColourBlock number='2'>
          <ProductLanderSection id="info">
            <article>
              <h2>Frequently Asked Questions</h2>
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      ​Is this a method that I can use on myself or is it for healing others too?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>  
                    <p>This is a process that is officially taught for you to use on yourself. However, Kaye has had many students give feedback that it has worked on their pets and loved ones too.</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    How long will it take to feel a result?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                  <p>After the first session of Permissioning and Transformation you will drop into your body and out of your headspace. This method unlocks your fixed perspective and this is when changes happen quickly. Many people experience a state of presence very quickly. The more you use the Permissions, the more your energy system registers it as your default setting – and you transform. We have had a student overcome years of suicidal depression within 2 weeks of using Permissioning and Transformation daily and long term anxiety overcome within days.</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Can I use this for anything?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Yes, anything.</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How can Permissioning and Transformation™ help with healing?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>A few months ago I was giving Quantum-Touch to a client called Max. It was his first session and he was highly stressed at the time, awaiting a diagnosis for various symptoms. I could see that his mind was racing, his body was rigid and that his high stress levels were stopping him from “letting the Quantum-Touch energy in”. This is an example of “the blocked pattern” in Quantum-Touch.</p>
                    <p>After 20 minutes I decided to try something new. I taught Max the sequence of Permissioning™ and watched his body literally melt into a state of relaxation. His shakiness began to settle and his mind let go of the stress within minutes. Now that his mind had “let go” and he was this relaxed I knew that Max would receive Quantum-Touch with ease. We stood back up and started the Quantum-Touch again - this time the healing flowed at an entirely different level.</p>
                    <p>Max has used Permissioning and Transformation™ every single day since I taught him in our session, and it plays a big part in his daily life. He uses it to reduce his stress levels and to experience a feeling of deep peace each day.</p>
                    <p>He has also learned Quantum-Touch since then and is able to enjoy the benefits of having access to both skill sets. In a nutshell, Permissioning and Transformation™ can take a person out of fight or flight within minutes which makes them incredibly receptive to healing. It is a quick solution to the blocked pattern.</p>
                    <p>The best part is that once the client knows how to use the techniques, they can then do so themselves.</p>
                    <p>The “Permission to Heal” topic will be covered within the Permission to Reset Your Nervous System workshop in January. This is a big aspect to how much a person allows themselves to heal and has a profound impact on the speed of the healing</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>How often should I use Permissioning?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                  <p>Ideally, daily and depending on how quickly you wish to shift into a higher state, you can use it 3 times a day. The process can be as long or short as you wish.</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Do I need to have any healing experience?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                  <p>No, anyone of any age or background can do this.</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Who is the creator and founder of Permissioning and Transformation</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ModalProvider backgroundComponent={FadingBackground}>
                      <FancyModalButton />
                    </ModalProvider>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>       
            </article>
          </ProductLanderSection>
        </BackgroundColourBlock>
      </Layout>
    </>
  )
}

export default Permissioning

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "permissioning-and-transformation" }) {
      title
      slug
      content
      uri
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
   

    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    allWpProduct(sort: {order: ASC, fields: menuOrder}, limit: 3, filter: {productsTag: {nodes: {elemMatch: {slug: {eq: "permissioning"}}}}}) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    permissioningTestimonials: wpPage(slug: { eq: "permissioning-transformation-testimonials" }) {
      title
      content
    }
}
`

