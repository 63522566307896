import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { regular16 } from "./typography";

const ProductLanderSection = styled.section`
  background: ${props => props.background ? props.background : 'transparent'};  
  ${baseGridStyles}

    blockquote {
        grid-column: span 4;
        border-left: 0;
        border-top: 4px solid var(--light-purple-mid);
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        &:first-of-type{
            border-top: 0;
        }

        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 7;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 3 / 11;
        }
    }
    p {
        ${regular16}
        margin-bottom: 1.6rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    
    article {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 4 / 10;
        }

        aside.nursing-midwifery-points {
            margin-top: 1.6rem;
            background-color: #ca088014;
            padding: 1.6rem;
            margin-bottom: 1.6rem;
            p:first-of-type {
                margin-top: 0;
            }
        }
    } 
`

export { 
  ProductLanderSection
}