import React from "react"
import parse from "html-react-parser"

const PatTestimonials = (data) => {
    return (
        <>
        {parse(data.testimonials)}
        </>
    )
}

export default PatTestimonials